/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import { makeStyles } from '@material-ui/core/styles';
import Layout from '../components/layout';
import DynamicComponent from '../utils/dynamic-component';
import ScrollToTop from '../components/scroll-up/scrollUp';
import SEO from '../components/seo-component/seo';
import CustomFonts from '../components/customFont/customfonts';

const useStyles = makeStyles(() => ({
  skipLink: (data) => ({
    position: 'absolute',
    top: '-40px',
    left: '0',
    padding: '6px',
    backgroundColor: '#000000 !important',
    color: '#FFF !important',
    border: '2px solid transparent !important',
    fontSize: '1.4em !important',
    borderRadius: '0 !important',
    borderBottomRightRadius: '8px !important',
    '&:focus': {
      left: '0',
      top: '0',
      transition: 'top 1s ease-out',
      zIndex: '9999'
    }
  }),
  main: {
    '& a': {
      borderBottom: '1px solid'
    }
  }
}));

export default function Home({ pageContext, data }) {
  const [user, setUser] = useState({});
  const [location, setLocation] = useState({});
  // const loginUser = () => {
  //   localStorage.setItem('user', JSON.stringify(true));
  //   window.location.reload();
  // };

  useEffect(() => {
    setLocation(window.location);
  }, []);

  const logoutUser = () => {
    localStorage.setItem('user', JSON.stringify(false));
    window.location.reload();
  };

  const capitalizeKey = (key) => key.split('_').map((word, index) => (index ? word.charAt(0).toUpperCase() + word.slice(1) : word)).join('');

  const getQueryParams = (search) => decodeURI(search)
    .replace('?', '')
    .split('&')
    .map((param) => param.split('='))
    .reduce((values, [key, value]) => {
      if (key) { if (key === 'campaign_operator' || key === 'campaignoperator' || key === 'utm_campaignoperator') { key = 'utm_campaign_operator'; } else if (key === 'master_campaign' || key === 'utm_mastercampaign' || key === 'mastercampaign') { key = 'utm_master_campaign'; } values[capitalizeKey(key)] = value; }
      return values;
    }, {});

  useEffect(() => {
    const getUTM = JSON.parse(localStorage.getItem('utm'));
    const urlsParams = getQueryParams(location.search);
    if (urlsParams && Object.keys(urlsParams).length) {
      if (JSON.stringify(getUTM) !== JSON.stringify(urlsParams)) {
        localStorage.setItem('utm', JSON.stringify(urlsParams));
      }
    }
  }, [location.search]);

  useEffect(() => {
    const getUser = JSON.parse(localStorage.getItem('user'));
    setUser(getUser);
  }, [user]);

  useEffect(() => {
    localStorage.setItem('privacyUrl', JSON.stringify(data?.contentfulPartnerSite?.encodedPrivacyUrl));
    localStorage.setItem('leadFormEndpoint', JSON.stringify(data.contentfulPartnerSite.adminDetails?.leadFormEndpoint || 'noLeadForm'));
    localStorage.setItem('ast', JSON.stringify(data.contentfulPartnerSite.hasAgentSelectionToolAstRequired));
    localStorage.setItem('flowOrder', JSON.stringify(data.contentfulPartnerSite.compBasicModalLeadForm?.referralFlowOrder || 'noLeadForm'));
  });

  return (
    <>
      <span id="consent_blackbar" className="cookie-consent" aria-label="cookie consent banner"> </span>
      {/* <div id="main" className={classes.main} tabIndex="-1"> */}
      <CustomFonts data={data?.contentfulPartnerTheme?.typography} />
      <Layout
        partnerCode={pageContext.partnerCode}
        navigation={data.contentfulPartnerSite.navigation}
        styles={data.contentfulPartnerTheme}
        footer={data.contentfulPartnerSite.siteFooter}
        metadata={data.contentfulPartnerTheme.partnerMetaData}
        contactFormDetails={data.contentfulPartnerSite.contactUsFormDetails}
        logout={logoutUser}
        siteType={data.contentfulPartnerSite.siteType}
        searchEnabled={data.contentfulPartnerSite.enableSearch}
        astEnabled={data.contentfulPartnerSite.hasAgentSelectionToolAstRequired}
        leadFormInfo={data.contentfulPartnerSite.compBasicModalLeadForm}
      >
        <SEO
          title={data.contentfulPartnerSite.homePage.seo?.pageTitle}
          description={data.contentfulPartnerSite.homePage.seo?.pageDescription}
          metaKeywords={data.contentfulPartnerSite.homePage.seo?.metaKeywords}
        />
        <h1 style={{
          position: 'absolute', width: '1px', height: '1px', padding: 0, margin: -'1px', overflow: 'hidden', clip: 'rect(0, 0, 0, 0)', whiteSpace: 'nowrap', border: 0
        }}
        >
          {data.contentfulPartnerSite.homePage.seo?.pageTitle}
        </h1>
        {
          /* Print all Component Names */
          // eslint-disable-next-line max-len
          data.contentfulPartnerSite?.homePage?.components
            .filter((component) => component?.sys?.contentType?.sys?.id)
            .map((component) => (
              <section key={component.id}>
                {DynamicComponent(component.sys.contentType.sys.id,
                  component, pageContext.partnerCode)}
              </section>
            ))
        }
        <ScrollToTop />
      </Layout>
      {/* </div> */}
    </>
  );
}

export const query = graphql`
  query homepageData($partnerCode: String!) {
    contentfulPartnerSite(partnerId: {eq: $partnerCode}) {
      entryTitle
      partnerId
      enableSearch
      hasAgentSelectionToolAstRequired
      encodedPrivacyUrl
      adminDetails{
        leadFormEndpoint
      }
      siteFooter {
        entryTitle
        footerContainer {
        ...compContainer
        }
      }
      compBasicModalLeadForm {
        referralFlowOrder
        callUs{
          raw
        }
        nextStepButtonLabel
        previousStepButtonLabel
        serviceSelectionScreenTitle
        serviceSelectionScreenSubTitle
        additionalInfoScreen {
          id
          screenTitle
          internalTitle
          screenSubTitle
          additionalQuestions {
            id
            entryTitle
            isThisQuestionRequired
            questionLabel {
              raw
            }
            reportingLabelCategory
            typeOfAnswer
            fieldOptions
          }
        }
        serviceSelectionBuyingIcon {
          file {
            contentType
            fileName
            url
          }
        }
        serviceSelectionSellingIcon {
          file {
            contentType
            fileName
            url
          }
        }
        serviceSelectionLegalDisclaimer {
          raw
        }
        customerInfoScreenTitle
        customerInfoScreenSubTitle
        showMemberField
        memberFieldLabel
        customerTextOptInLegalDisclaimer {
          raw
        }
        customerInformationLegalDisclaimer {
          raw
        }
        buyingLocationScreenTitle
        sellingLocationScreenTitle
        confirmationScreen {
          screenTitle
          serviceInfoSectionTitle
          customerInfoSectionTitle
          privacyOptInLegalDisclaimer{
            raw
          }
          abadOptInLegalDisclaimer{
            raw
          }
          legalFootnotes{
            raw
          }
        }
        thankYouScreen {
          closeButtonLabel
          bodyText {
            raw
          }
          screenTitleText {
            raw
          }
        }
      }
      navigation {
        primaryLogoHeader {
          file {
            url
            contentType
            fileName
          }
          description
        }
        menus {
          id
          menuLabel
          isInternalLink
          isParallax
          menuItems {
            entryTitle
            label
            link {
              ... on ContentfulInternalLink {
                referenceToPage {
                  pageName
                }
                entryTitle
              }
            }
          }
          menuLink {
            link {
              ... on ContentfulInternalLink {
                referenceToPage {
                  pageName
                  contentful_id
                }
              }
              ... on ContentfulWebLink {
                id
                url
                openInANewTab
              }
              ... on ContentfulPageAction {
                id
                entryTitle
                actionId
              }
            }
          }
        }
        buttonAction {
          entryTitle
          id
          contentful_id
          label
          link {
            ... on ContentfulPageAction {
              id
              entryTitle
              actionId
            }
          }
        }
      }
      homePage {
        pageName
        seo {
          pageDescription
          pageTitle
          metaKeywords
        }
        contentful_id
        components {
          ...compTextWithButton
          # ...compQumuCarousel 
          ...compMultipleCarousel
          ...compRewardCalc
          ...compQuoteCarousel
          ...compContainer
          ...compBanner
        }
      }
      otherPages {
        pageName
        entryTitle
        contentful_id
      }
    }
    contentfulPartnerTheme(partnerId: {eq: $partnerCode}) {
      entryTitle
      contentful_id
      buttonStyle {
        boxShadow
        buttonBackgroundColor
        buttonHoverColor
        entryTitle
        buttonType
        buttonFontSize
        boxShadowBlur
        boxShadowColor
        boxShadowHorizontalPosition
        boxShadowVerticalPosition
        buttonFontFamily
        googleFontFamily
        borderRadius
        buttonTextColor
      }
      menuColor
      entryTitle
      partnerId
      googleTagManagerId
      backgroud {
        contentful_id
        entryTitle
        bodyBackgroundColor
        footerBackgroundColor
        progressBarBackgroundColor
        progressBarBackgroundColorSecondary
        selectedIconButtonColor
      }
      grid {
        contentful_id
        gridSpacing
        gridJustifyContent
        gridAlignments
      }
      favIcon {
        favIcon {
          file {
            url
          }
        }
      }
      typography {
        bodyFontSize
        bodyTextColor
        contentful_id
        entryTitle
        headingColor
        headerMenuLinkColor
        headingFontFamily
        googleFontBody
        googleFontHeading
        bodyFontFamily
        bodyLineHeight
        h1
        h2
        h2FontWeight
        h3
        h4
        h5
        h6
        headingLetterSpacing
        headingLineHeight
        linkTextColor
        footerTextColor
        footerLinkColor
        svgIconColor
        customHeadingFont {
          fontFamilyName
          fontUrl {
            file {
              fileName
              url
            }
          }
        }
        customBodyFont {
          fontFamilyName
          fontUrl {
            file {
              fileName
              url
            }
          }
        }  
      }
      astThemeTypography {
        bodyFontSize
        bodyTextColor
        headingTextColor
        linkTextColor
        progressBarColor
      }
      partnerAst {
        entryTitle
        consentToCallText {
          raw
        }
        legalAgreements {
          disclosureAcronym
          disclosureText {
            raw
          }
        }
        rewardDisclosureText {
          raw
        }
        buyValueRewardTable {
          entryTitle
          childrenContentfulRewardTableRewardTiersJsonNode {
            max
            min
            reward
          }
          enableTableLookupCalculator
          rewardUnit
        }
        sellValueRewardTable {
          entryTitle
          childrenContentfulRewardTableRewardTiersJsonNode {
            value
            reward
          }
          enableTableLookupCalculator
          rewardUnit
        }
        buyingSellingPaths {
          ...contentfulBuySellOption
        }
        screensWithContent {
          ...contentfulModifiedScreenContent
        }
        brandLogos{
          ... on ContentfulBrandLogo{
            identifier
            entryField
            logo {
              file {
                url
              }
            }
          }
        }
        compBuyingThankYouScreen {
          ... on ContentfulCompThankyouScreen {
            internalTitle
            title {
              raw
            }
            displayAgentProfilePictures
            bodyText {
              raw
            }
            showCloseButton
            closeButtonLabel
            iconAlternative {
              file {
                url
              }
            }
            additionalCtAs{
              entryTitle
              displayLabelOne
              label
              url
              labelOneShouldBeDisplayedAs
              displayLabelTwo
              labelTwo
              externalUrlTwo
              labelTwoShouldBeDisplayedAs
            }
          }
        }
        compSellingThankyouScreen {
          ... on ContentfulCompThankyouScreen {
            internalTitle
            title {
              raw
            }
            displayAgentProfilePictures
            bodyText {
              raw
            }
            showCloseButton
            closeButtonLabel
            iconAlternative {
              file {
                url
              }
            }
            additionalCtAs{
              entryTitle
              displayLabelOne
              label
              url
              labelOneShouldBeDisplayedAs
              displayLabelTwo
              labelTwo
              externalUrlTwo
              labelTwoShouldBeDisplayedAs
            }
          }
        }
        compBothThankyouScreen {
          ... on ContentfulCompThankyouScreen {
            internalTitle
            title {
              raw
            }
            displayAgentProfilePictures
            bodyText {
              raw
            }
            showCloseButton
            closeButtonLabel
            iconAlternative {
              file {
                url
              }
            }
            additionalCtAs{
              entryTitle
              displayLabelOne
              label
              url
              labelOneShouldBeDisplayedAs
              displayLabelTwo
              labelTwo
              externalUrlTwo
              labelTwoShouldBeDisplayedAs
            }
          }
        }
        compOfframpThankyouScreen {
          ... on ContentfulCompThankyouScreen {
            internalTitle
            title {
              raw
            }
            displayAgentProfilePictures
            bodyText {
              raw
            }
            showCloseButton
            closeButtonLabel
            iconAlternative {
              file {
                url
              }
            }
            additionalCtAs{
              entryTitle
              displayLabelOne
              label
              url
              labelOneShouldBeDisplayedAs
              displayLabelTwo
              labelTwo
              externalUrlTwo
              labelTwoShouldBeDisplayedAs
            }
          }
        }
      }
      partnerMetaData {
        clientNumber
        entryTitle
        hesAstBuyCode
        hesAstBuySellCode
        hesAstSellCode
        leadSource
        appType
        partner
        processor
      }
    }
  }

  fragment compAccordion on ContentfulCompAccordion {
    sys {
      type
      contentType {
        sys {
          id
        }
      }
    }
    id
    accordionBody {
      ... on ContentfulCompContainer {
        rows {
          columns {
            component {
              ...compRichTextBlock
              ...compSubAccordion
              ...compImageWithText2
            }
          }
        }
      }
    }
    accodionHeaderLabel
  }

  fragment compSubAccordion on ContentfulCompSubAccordion {
    sys {
      contentType {
        sys {
          id
        }
      }
    }
    id
    subAccordionHeaderLabel
    subAccordionBody {
      rows {
        columns {
          component {
            ...compRichTextBlock
            ...compImageWithText2
          }
        }
      }
    }
  }

  fragment contentfulModifiedScreenContent on ContentfulModifiedScreenContent {
    entryTitle
    screenType
    questionSubtext
    bodyHelperText
    questionForBothType {
      questionForBothType
    }
    helperLabel
    helperText {
      internal {
        content
      }
    }
    showHelperText
    screenCode
    
    
    screenIndex
    screenTitle
    screenEnabled
    optInToTextingText {
      raw
    }
    backgroundColor
    isThisScreenRequiredForOperationTypeBoth
    isThisScreenRequiredForOperationTypeSell
    isThisScreenRequiredForOperationTypeBuy
    question {
      question
    }
    showMemberField
    memberFieldLabel
    memberFieldHelperText {
      raw
    }
    residenceLegalDisclaimer {
      raw
    }
    slug
    contentful_id
  }

  fragment contentfulBuySellOption on ContentfulBuySellOption {
    label
    entryTitle
    image {
      file {
        url
      }
    }
    hasBuy
    hasSell
    backgroundColorOfTheIconWhenSelected
  }

  fragment compTabContainer on ContentfulCompTabContainer {
    contentful_id
    entryTitle
    tabType
    tabHeaderBackgroundColor
    activeTabIndicatorColor
    tabBodyBackgroundColor
    tabBodyBoxShadow
    tabItems {
      contentful_id
      entryTitle
      tabLabel
      tabBody {
        ...compImageWithText2
        ...compRichTextBlock
        ...compAccordion
      }
    }
    sys {
      contentType {
        sys {
          id
        }
      }
    }
  }

  # fragment compNColumnLayout on ContentfulCompNColumnLayout {
    #     id
    #     entryTitle
    #     title
    #     isContentInCard
    #     sys {
    #       contentType {
    #         sys {
    #           id
    #         }
    #       }
    #     }
    #     subComponents {
    #       ... on ContentfulCompCardWithTextAndLink {
    #         id
    #         entryTitle
    #         title
    #         subText {
    #           raw
    #         }
    #       }
    #     }
    #   }

  fragment compMultipleCarousel on ContentfulCompMultipleCarousel {
    id
    entryTitle
    selectCarouselType
    frames {
      image {
        file {
          url
        }
        description
      }
      contentAlignment
      overlayAlignment
      eyebrowText
      heading
      bodyText {
        raw
      }
      primaryCallToAction {
        displayPrimaryCta
        labelForPrimaryCta
      }
    }
    sys {
      contentType {
        sys {
          id
        }
      }
    }
  }

  # fragment compBrandPartnerLogoStrip on ContentfulCompBrandPartnerLogoStrip {
    #     areLogosInColor
    #     id
    #     entryTitle
    #     partnerLogo {
    #       id
    #       file {
    #         url
    #       }
    #     }
    #     description {
    #       raw
    #     }
    #     sys {
    #       contentType {
    #         sys {
    #           id
    #         }
    #       }
    #     }
    #   }

  fragment compImageWithText2 on ContentfulCompImageWithText2 {
    id
    alignText
    titleText
    titleUnderline
    imageMaxHeight
    subText {
      raw
    }
    image {
      file {
        url
        contentType
        fileName
      }
      description
    }
    paddingLeft
    paddingRight
    isCtaButtonEnabled
    button {
      primaryCtaShouldOpenInTheSameTab
      primaryCtaShouldBeDisplayedAs
      displayPrimaryCta
      labelForPrimaryCta
    }
    hasAnchorLinkWithDottedUnderline
    linkTextColor
    sys {
      contentType {
        sys {
          id
        }
      }
    }
  }

  fragment compRewardCalc on ContentfulCompRewardCalc {
    id
    calculatorTitle
    rewardDisclaimer
    entryTitle
    title
    backgroundColor
    backgroundImageOverlayColor
    backgroundImageOverlayOpacity
    calcRewardTextColor
    cashBackTextColor
    isUnderline
    subText {
      subText
    }
    backgroundImage {
      file {
        url
      }
    }
    enableCashBackPercentage
    cashBackPercentage{
      cashback
    }
    cashBackTable {
      max
      min
      cashback
    }
    showSign
    calculatorLink {
      link {
        ... on ContentfulPageAction {
          entryTitle
        }
      }
      label
    }
    rewardCalcViewType
    isRewardInLinkColor
    itemId
    sys {
      contentType {
        sys {
          id
        }
      }
    }
  }

  fragment compTextWithButton on ContentfulCompTextWithButton {
    id
    entryTitle
    title
    button {
      link {
        label
        link {
          ... on ContentfulPageAction {
            entryTitle
          }
        }
      }
    }
    subText {
      raw
    }
    backgroundColor
    textColor
    sys {
      contentType {
        sys {
          id
        }
      }
    }
  }

  fragment compQuoteCarousel on ContentfulCompQuoteCarousel {
    id
    entryTitle
    sys {
      contentType {
        sys {
          id
        }
      }
    }
    image {
      file {
        url
      }
      description
    }
    quoteItems {
      attributeText
      entryTitle
      quoteText {
        quoteText
      }
    }
  }

  # fragment compQumuCarousel on ContentfulCompQumuCarousel {
  #  id
  #  sys {
  #    contentType {
  #      sys {
  #        id
  #      }
  #    }
  #  }
  #  backgroundColor
  #  showImages
  #  subText {
  #    raw
  #  }
  #  title
  #  qumuVideoEmbed {
  #    qumuEmbedCode {
  #      internal {
  #        content
  #      }
  #    }
  #  }
  #}

  fragment compFaqAccordion on ContentfulCompFaqAccordion {
    id
    sys {
      contentType {
        sys {
          id
        }
      }
    }
    title
    displayFaqTitleAsH1Tag
    faqItem {
      id
      question
      answer {
        raw
      }
    }
  }

  fragment compImageOnly on ContentfulCompImageOnly {
    id
    maxHeight
    ifItIsHalfWidthHeroBannerImage
    image {
      file {
        url
      }
      description
    }
    sys {
      type
      contentType {
        sys {
          id
        }
      }
    }
  }

  fragment compFooterLinks on ContentfulFooterLinks {
    entryTitle
    links {
      label
      link {
        ... on ContentfulWebLink {
          id
          url
          openInANewTab
        }
        ... on ContentfulInternalLink {
          id
          referenceToPage {
            pageName
          }
        }
      }
    }
    sys {
      type
      contentType {
        sys {
          id
        }
      }
    }
  }

  fragment compImageWithFocalPoint on ContentfulImageWithFocalPoint {
    id
    image {
      file {
        url
        details {
          size
          image {
            height
            width
          }
        }
      }
      gatsbyImageData
      fluid {
        srcSetWebp
      }
      description
    }
    focalPoint {
      focalPoint {
        x
        y
      }
    }
    sys {
      contentType {
        sys {
          id
        }
      }
    }
  }

  fragment compRichTextBlock on ContentfulCompRichTextBlock {
    id
    richText {
      raw
    }
    entryTitle
    anchorLinkColor
    hasAnchorLinkWithDottedUnderline
    sys {
      contentType {
        sys {
          id
        }
      }
    }
  }

  fragment compTable on ContentfulCompTable {
    id
    entryTitle
    description
    cellPadding
    fixedHeader
    headerBackground
    headerCellColor
    rowHighlightColor
    evenCellBackground
    tableHeader
    tableWithPagination
    tableRows {
      id
      cellValues
    }
    sys {
      type
      contentType {
        sys {
          id
        }
      }
    }
  }

  fragment compListContainer on ContentfulCompListContainer {
    id
    entryTitle
    listItems {
      id
      listBody {
        ...compImageOnly
        ...compRichTextBlock
      }
    }
    sys {
      contentType {
        sys {
          id
        }
      }
    }
  }

  fragment compToggle on ContentfulCompToggle {
    id
    entryTitle
    toggleButton1Label
    toggleButton2Label
    buyingHomeBody {
      toggleBodyImage {
        file {
          url
        }
      }
      toggleBodyText {
        raw
      }
    }
    sellingHomeBody {
      toggleBodyImage {
        file {
          url
        }
      }
      toggleBodyText {
        raw
      }
    }
    sys {
      contentType {
        sys {
          id
        }
      }
    }
  }

  fragment compCardsContainer on ContentfulCompCardsContainer{
    title
              sys {
                contentType {
                  sys {
                    id
                  }
                }
              }
              cards {
                cardLabel
                cardImage {
                  file {
                    url
                  }
                }
                cardContent {
                  link {
                    ... on ContentfulInternalLink {
                      referenceToPage {
                        pageName
                      }
                    }
                  }
                }
              }
    }

    fragment compTimeline on ContentfulCompTimeline {
      sys {
        contentType {
          sys {
            id
          }
        }
      }
      timelineItems {
        body
        title
        text
        circleColor
      }
    }

    fragment compVideoContainer on ContentfulCompVideoEmbedded {
      id
        sys {
          type
        contentType {
          sys {
            id
          }
        }
      }
      embeddedUrl {
        id
        embeddedUrl
      }
    }
    fragment compBanner on ContentfulCompBanner {
      sys {
       type
       contentType {
         sys {
           id
         }
       }
     }
     title
     alignItems
     justifyContent
     alignText
     displaySubText 
     bannerLogoPosition
     bannerDescriptionRichText {
       raw
     }
     button {
       primaryCtaShouldOpenInTheSameTab
       primaryCtaShouldBeDisplayedAs
       displayPrimaryCta
       labelForPrimaryCta
     }
     bannerImage {
       file {
         url
       }
       description
     }
     bannerLogo {
       file {
         url
       }
       description
     }
   }
  fragment compContainer on ContentfulCompContainer {
    id
    backgroundColor
    contentful_id
    entryTitle
    backgroundImageOverlayColor
    backgroundImageOverlayOpacity
    anchorLinkId
    backgroundImage {
      file {
        url
      }
    }
    sys {
      type
      contentType {
        sys {
          id
        }
      }
    }
    rows {
      id
      entryTitle
      alignItems
      alignItems
      justifyContent
      alignText
      contentful_id
      topPadding
      bottomPadding
      leftPadding
      rightPadding
      anchorLinkId
      columns {
        id
        entryTitle
        contentful_id
        hasHoverEffect
        isCard
        columnSize
        backgroundColor
        hoverBackgroundColor
        textColor
        component {
          ...compImageWithText2
          ...compImageOnly
          ...compFooterLinks
          ...compImageWithFocalPoint
          ...compTable
          ...ContentfulCompRichTextBlock
          ...compMultipleCarousel
          ...compFaqAccordion
          ...compTabContainer
          ...compListContainer
          ...compToggle
          ...compCardsContainer
          ...compTimeline
          ...compVideoContainer
        }
      }
    }
  }
`;
